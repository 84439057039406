import React, { useEffect, useState, useContext, useRef } from 'react';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './YourQuoteStyles';
import { Grid, Divider, Box } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MuModal } from '../../components/atoms';
import uuid from 'uuid';

import clsx from 'clsx';
import { StepContext, IStepData, quoteDataFleet, steps, Step, initialNavStepperData } from '../../contexts/StepContext';
import {
  DocumentTitle,
  Typography,
  RadioButtonGroup,
  ButtonPrimary,
  ButtonSecondary,
  TextField,
  YourCover,
} from '../../components/atoms';
import { dateIsValid } from '../../utils/dateFormattingUtils';

import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { format, addDays, isFuture, startOfDay, endOfDay } from 'date-fns';

import YourQuoteSchema from './YourQuoteSchema';
import axios from 'axios';
import { calculateCostTotal } from '../../utils/yourQuoteUtils';
import EmailMeThisQuoteModalBody from '../../components/atoms/EmailMeThisQuoteModalBody/EmailMeThisQuoteModalBody.component';


export const YourQuote: React.FC = () => {
  const { activeStep, updateActiveStep, loading, updateLoading, updateQuotes, updateData, data } = useContext(
    StepContext,
  );
  const [vehicleCostCalculatorData, setVehicleCostCalculatorData] = useState<any>(
    data.coverOptions ? data.coverOptions : [],
  );
  //vehicle cost calculator not implemented yet
  const [vehicleAmountModalActive, setVehicleAmountModalActive] = useState(false);
  const [emailMeThisQuoteModalActive, setEmailMeThisQuoteModalActive] = useState(false);

  const [emailAddress, setEmailAddress] = useState(data.emailAddress !== undefined ? data.emailAddress : '');
  const [localTotalCost, setTotalLocalCost] = useState(0);
  const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null | undefined | string>(
    data.coverStartDate !== undefined ? data.coverStartDate : null,
  );

  const location = useLocation();
  const id: string | null = new URLSearchParams(location.search).get('id');
  const partnerizeRef: string | null = new URLSearchParams(location.search).get('clickref');

  const minimumStartDate = format(addDays(startOfDay(new Date()), 1), 'yyyy/MM/dd');
  const maximumDate = format(addDays(startOfDay(new Date()), 30), 'yyyy/MM/dd');
  const maximumNumberOfDaysYup = endOfDay(addDays(new Date(), 30));

  
  useEffect(() => {

    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_EMAIL_QUOTE_GET_ENDPOINT}/${id}`,
        );

        // if theres any items in array go ahead as normal
        if (response.data.data[0]) {

          const data = {
            ...initialNavStepperData,
            ...response.data.data[0],
            coverStartDate: isFuture(new Date(response.data.data[0].startDate))
              ? response.data.data[0].startDate
              : null,
            quoteId: id,
            quote_id: id,
            quoteTotal: response.data.data[0].quoteTotal,
          };

          updateData(data);

          reset(data);
          updateLoading(false);
        } else {
          window.location.href = '/';
        };
      } catch (err) {
        console.error(err);
      };
    };

    id && data.quoteId === null ? getData() : updateLoading(false);
    updateLoading(false);
  }, []);

  useEffect(() => {
    const generatedUuid = uuid.v4();
    updateData({
      ...data,
      quote_id: data.quote_id ? data.quote_id : generatedUuid,
      partnerizeRef: partnerizeRef,
    });
  }, []);

  const breakdownCoverRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const classes = useStyles();
  const schema = yup.object().shape({
    moreThanFiftyVehicles: yup.string().required(),
    breakdownCoverLocation: yup.string().required(),
    vehicleRepairLocation: yup.string().optional(),
    coverType: yup.string().required(),
    vehicleCount: yup
      .number()
      .typeError('Let us know how many vehicles need cover.')
      .min(1, 'A minimum of 1 vehicle is required.')
      .max(999)
      .required('Let us know how many vehicles need cover.'),
    vehicleId: yup.string().nullable(),
    coverStartDate: yup
      .date()
      .required('Let us know when your cover needs to start.')
      .min(minimumStartDate, 'Cover start date cannot be in the past.')
      .max(maximumNumberOfDaysYup, 'Cover start date cannot be more than 30 days from today.')
      .nullable(),
  });

  const { handleSubmit, control, watch, reset, setValue, formState, trigger, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: data,
    shouldFocusError: true,
    shouldUnregister: false, // Maintain values of hidden vehicle cost calculator component
  });

  const moreThanFiftyVehicles = watch('moreThanFiftyVehicles');
  const breakdownCoverLocation = watch('breakdownCoverLocation');
  const coverType = watch('coverType');
  const vehicleCount = watch('vehicleCount');
  const quoteTotal = watch('quoteTotal');

  useEffect(() => {
    updateActiveStep(1);

    setTotalLocalCost(quoteTotal);
    const getVehicleCostCalculatorData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_OPTIONS_ENDPOINT}`,
      );
      updateQuotes(data);
      setVehicleCostCalculatorData(data);
    };

    if (vehicleCostCalculatorData.length === 0) getVehicleCostCalculatorData();
    if (vehicleCostCalculatorData.Items) {
      const totalQuote = calculateCostTotal(vehicleCostCalculatorData.Items, coverType, vehicleCount);
      if (totalQuote) {
        setValue('quoteTotal', totalQuote);
        setTotalLocalCost(totalQuote);
      } else {
        setTotalLocalCost(0);
      }
    } else if (vehicleCostCalculatorData) {
      const totalQuote = calculateCostTotal(vehicleCostCalculatorData, coverType, vehicleCount);
      if (totalQuote) {
        setValue('quoteTotal', totalQuote);
        setTotalLocalCost(totalQuote);
      } else {
        setTotalLocalCost(0);
      }
    }

    trigger('quoteTotal');
  }, [watch('coverType'), watch('vehicleCount')]);

  const onSubmit = (stepData: IStepData) => {
    const vehicleCount: any = Number(getValues('vehicleCount'));

    const numberVehicleCount = Number(data.vehicleCount);

    let newVehiclesArray = [...data.vehicles];
    let reactIndex =
      data.vehicles.length > 0
        ? data.vehicles.reduce((p, c) => (p.reactIndex > c.reactIndex ? p : c)).reactIndex + 1
        : newVehiclesArray.length + 1;
    if (data.quote_id !== null && data.vehicles.length === 0) {
      for (let i = 0; i < vehicleCount; i++) {
        newVehiclesArray.push({
          reactIndex: reactIndex,
          reg: '',
          make: '',
          model: '',
          year: null,
          complete: false,
        });
        reactIndex++;
      }
    } else if (vehicleCount !== numberVehicleCount && vehicleCount !== undefined) {
      // Add new vehicle items

      if (vehicleCount > numberVehicleCount) {
        for (let i = numberVehicleCount; i < vehicleCount; i++) {
          newVehiclesArray.push({
            reactIndex: reactIndex,
            reg: '',
            make: '',
            model: '',
            year: null,
            complete: false,
          });
          reactIndex++;
        }
      } else if (vehicleCount < numberVehicleCount) {
        newVehiclesArray = data.vehicles.sort((a, b) => a.reactIndex - b.reactIndex).slice(0, vehicleCount);
      }
    } else if (data.quoteId !== null && data.vehicles.length === 0) {
      for (let i = 0; i < numberVehicleCount; i++) {
        newVehiclesArray.push({
          reactIndex: reactIndex,
          reg: '',
          make: '',
          model: '',
          year: null,
          complete: false,
        });
        reactIndex++;
      }
    }

    const coverOptionsData: string[] = [];
    const formData = data.coverOptions;
    if (formData?.Items) {
      formData?.Items.map((element) => {
        if (element.coverType === coverType) {
          element['price'] = quoteTotal;
          element['number'] = vehicleCount;
        } else {
          element['price'] = 0;
          element['number'] = 0;
        }
        return coverOptionsData.push(element);
      });
    } else {
      formData.map((element) => {
        if (element.coverType === coverType) {
          element['price'] = quoteTotal;
          element['number'] = vehicleCount;
        } else {
          element['price'] = 0;
          element['number'] = 0;
        }
        return coverOptionsData.push(element);
      });
    }

    if (vehicleCount > 50) {
      setVehicleAmountModalActive(true);
    } else {
      updateActiveStep(activeStep + 1);

      updateData({
        ...data,
        ...stepData,
        coverOptions: coverOptionsData,
        coverStartDate:
          stepData.coverStartDate !== null
            ? format(new Date(stepData.coverStartDate), 'yyyy/MM/dd')
            : stepData.coverStartDate,
        emailAddress: emailAddress,
        vehicleCount: vehicleCount,
        vehicles: newVehiclesArray,
        quoteTotal: getValues('quoteTotal'),
        quote_id: data.quote_id,
        partnerizeRef: data.partnerizeRef,
      });
      history.push(steps[Step.VEHICLE_DETAILS].url);
    }
  };

  const handleMoreThanFiftyVehiclesChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    updateData({
      ...data,
      moreThanFiftyVehicles: e.target.value,
    });

    if (e.target.value === 'true') {
      setVehicleAmountModalActive(true);
      //reset(quoteDataFleet);
    }
  };

  const onCancelClick = () => {
    setVehicleAmountModalActive(false);
    reset(quoteDataFleet);
  };

  const handleCoverTypeChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const handleFormChange = new Promise<void>((res) => {
      if (e.target.name === 'breakdownCoverLocation') {
        // Reset value
        if (e.target.value === 'THE UK') {
          setValue('coverType', '', { shouldValidate: true });
          trigger('coverType').then(() => {
            setValue('vehicleRepairLocation', '', { shouldValidate: true });
            setValue('breakdownCoverLocation', 'THE UK', { shouldValidate: true });
          });
        }
        // National + European
        else if (e.target.value === 'THE UK AND EUROPE') {
          setValue('coverType', 'National + European', { shouldValidate: true });
        }
      } else if (e.target.name === 'vehicleRepairLocation') {
        // Standard
        if (breakdownCoverLocation === 'THE UK' && e.target.value === 'THE NEAREST GARAGE') {
          setValue('coverType', 'Standard', { shouldValidate: true });
        }
        // National
        else if (breakdownCoverLocation === 'THE UK' && e.target.value === 'ANYWHERE IN THE UK') {
          setValue('coverType', 'National', { shouldValidate: true });
        }
      }

      res();
    });
    await handleFormChange;
  };

  function onStartDateChange(startDate, onChange) {
    if (dateIsValid(startDate) ) {
      setSelectedStartDate(format(new Date(startDate), 'yyyy/MM/dd'));
      setValue('coverStartDate', format(new Date(startDate), 'yyyy/MM/dd'));
      updateData({
        ...data,
        coverStartDate: format(new Date(startDate), 'yyyy/MM/dd'),
      });
      
      
      onChange(startDate);
    }
  }

  const handleOnKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key == 'Delete' || event.key == 'Backspace') {
      // allow deletes - Firefox issue
    } else {
      const pattern = /[0-9\s]/;
      const inputChar = String.fromCharCode(event.charCode);

      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }

      if ((event.target as HTMLInputElement).value.length > 1) {
        // too many characters
        event.preventDefault();
      }
    }
  };

  
  if (loading) return <></>;

  return (
    <PageTemplate>
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID} - Your Quote`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={12} className={classes.gridMain}>
          <Controller control={control} name="coverType" defaultValue={data.coverType} render={() => <></>} />
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Typography className={classes.detailsCorrectHeading} variant="h1">
              Let&apos;s get this show on the road.
            </Typography>

            <Box className="mt2">
              <Typography forwardedRef={breakdownCoverRef} variant="h2">
                How many vehicles need breakdown cover?
              </Typography>
              <Typography className="pt1" variant="body1">
                You&apos;ll be able to let us know the exact number of vehicles shortly.
              </Typography>
            </Box>
            <Box className={classes.maxWidth30}>
              <RadioButtonGroup
                name="moreThanFiftyVehicles"
                control={control}
                defaultValue={data.moreThanFiftyVehicles}
                data={YourQuoteSchema.vehicleAmountData}
                onChange={handleMoreThanFiftyVehiclesChange}
                watch={watch}
                className={classes.inputFields}
              />
            </Box>
            {moreThanFiftyVehicles === 'false' ? (
              <>
                <Box className="mt2">
                  <Typography variant="h2">Where do you need cover?</Typography>
                  <Typography className="pt1" variant="body1">
                    If you have some vehicles that only need UK cover, and other vehicles that need cover in Europe as
                    well, call us on 03452460637.
                  </Typography>
                </Box>
                <Box className={classes.maxWidth30}>
                  <RadioButtonGroup
                    name="breakdownCoverLocation"
                    control={control}
                    defaultValue={data.breakdownCoverLocation}
                    data={YourQuoteSchema.breakDownCoverLocationData}
                    onChange={handleCoverTypeChange}
                    watch={watch}
                  />
                </Box>
                {breakdownCoverLocation === 'THE UK AND EUROPE' ? (
                  <Box className={classes.ukInfoBox}>
                    <Typography>
                      Before you continue... We want you to know your car must be under 16 years old to get cover in
                      Europe.
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {breakdownCoverLocation === 'THE UK' ? (
              <>
                <Box className="mt2">
                  <Typography forwardedRef={breakdownCoverRef} variant="h2">
                    Do you need national recovery?
                  </Typography>
                  <Typography className="pt1" variant="body1">
                    If we can&apos;t fix a vehicle at the roadside, we can take it to a garage with 10 miles of your
                    breakdown. Or, with <b>national recovery</b>, we can take the vehicle and anyone in it to a single
                    destination of your choice.
                  </Typography>
                  <Typography className="pt1" variant="body1">
                    If you&apos;d like different cover features for different vehicles on your policy, please call 0345
                    246 0637.
                  </Typography>
                </Box>
                <Box className={classes.maxWidth30}>
                  <RadioButtonGroup
                    name="vehicleRepairLocation"
                    control={control}
                    defaultValue={data.vehicleRepairLocation}
                    data={YourQuoteSchema.vehicleRepairLocationData}
                    onChange={handleCoverTypeChange}
                    watch={watch}
                  />
                </Box>
              </>
            ) : (
              <></>
            )}
            {coverType !== '' ? (
              <>
                <Box className="mt2">
                  <Typography variant="h2">When do you need your cover to start?</Typography>
                  <Controller
                    name="coverStartDate"
                    control={control}
                    
                    render={({ field: { onChange } }) => (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Box className={clsx(classes.datePicker, 'pt1')}>
                          <KeyboardDatePicker
                            id="coverStartDate"
                            name="coverStartDate"
                            variant="inline"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            autoOk={true}
                            disablePast={true}
                            helperText={null}
                            className={classes.customDatePicker}
                            minDate={minimumStartDate}
                            maxDate={maximumDate}
                            placeholder="DD/MM/YYYY"
                            value={data.coverStartDate ? data.coverStartDate : selectedStartDate}
                            onChange={(value) => onStartDateChange(value, onChange)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            error={formState.errors.coverStartDate ? true : false}
                          />
                        </Box>
                      </MuiPickersUtilsProvider>
                    )}
                  />
                  <Typography className="fieldError">{formState.errors.coverStartDate?.message}</Typography>
                </Box>
                <Box className="mt2">
                  <Typography variant="h2">How many vehicles need cover?</Typography>
                  <TextField
                    className={clsx(classes.inputFields, 'pt1')}
                    id="vehicleCount"
                    name="vehicleCount"
                    control={control}
                    placeholder="Enter a number between 1 and 50"
                    defaultValue={data.vehicleCount}
                    onKeyPress={handleOnKeyPress}
                    error={formState.errors.vehicleCount ? true : false}
                  />
                  <Typography className="fieldError">{formState.errors.vehicleCount?.message}</Typography>
                </Box>

                <Box className="mt3">
                  <YourCover coverType={coverType} coverPrice={localTotalCost} numberOfVehicles={vehicleCount} />
                </Box>
                <Divider className="divider2-mb-1"></Divider>

                <div className={classes.actionButton}>
                  <ButtonPrimary type="submit">Continue</ButtonPrimary>
                  <ButtonSecondary
                    disabled={id ? !formState.isValid || !formState.isDirty : !formState.isValid}
                    className='ml1'
                    onClick={() => setEmailMeThisQuoteModalActive(true)}
                  >
                    Email me this quote
                  </ButtonSecondary>
                </div>
              </>
            ) : (
              <></>
            )}
          </form>
        </Grid>
        <Grid item xs={12} lg={4}></Grid>
        <EmailMeThisQuoteModalBody
          formData={data.coverOptions}
          localStateValues={getValues()}
          emailMeThisQuoteModalActive={emailMeThisQuoteModalActive}
          onCancelClick={() => setEmailMeThisQuoteModalActive(false)}
          setEmailAddress={setEmailAddress}
        />
        <MuModal
          isOpen={vehicleAmountModalActive}
          onCloseClick={onCancelClick}
          ariaLabelledBy={'Please give us a call'}
          isSmallClose={true}
          isBorderStyle={true}
          isCloseBtn={true}
        >
          <Typography variant="h3">{YourQuoteSchema.pleaseGiveUsACallModalData.heading}</Typography>
          <Typography paragraph={true} className={classes.modalPText}>
            {YourQuoteSchema.pleaseGiveUsACallModalData.body.text}
          </Typography>
          <Typography className={classes.modalPText}>
            <b>{YourQuoteSchema.pleaseGiveUsACallModalData.body.number}</b>
          </Typography>
          <Typography paragraph={true} className={classes.modalPText}>
            {YourQuoteSchema.pleaseGiveUsACallModalData.body.text2}
          </Typography>
          <Typography paragraph={true} className={classes.modalPText}>
            {YourQuoteSchema.pleaseGiveUsACallModalData.body.Openinghours} <br />{' '}
            {YourQuoteSchema.pleaseGiveUsACallModalData.body.OpeninghoursTime.monFri} <br />{' '}
            {YourQuoteSchema.pleaseGiveUsACallModalData.body.OpeninghoursTime.sat} <br />{' '}
            {YourQuoteSchema.pleaseGiveUsACallModalData.body.OpeninghoursTime.sun}
          </Typography>
        </MuModal>
      </Grid>
    </PageTemplate>
  );
};

export default YourQuote;
